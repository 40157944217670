import { Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useMsal } from "@azure/msal-react";

const LoggedUserInfo = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  return (
    <div>
      <PersonIcon color="primary" />
      <Typography color="primary" fontFamily="sans-serif" fontSize="large">
        {account?.name}
      </Typography>
    </div>
  );
};
export default LoggedUserInfo;
