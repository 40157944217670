import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import Prestashop1 from '../../assets/prestashop/PrestaShop1.png';
import Prestashop2 from '../../assets/prestashop/PrestaShop2.png';
import Prestashop3 from '../../assets/prestashop/PrestaShop3.png';
import Prestashop4 from '../../assets/prestashop/PrestaShop4.png';
import Prestashop5 from '../../assets/prestashop/PrestaShop5.png';
import Prestashop6 from '../../assets/prestashop/PrestaShop6.png';
import Prestashop7 from '../../assets/prestashop/PrestaShop7.png';
import Prestashop8 from '../../assets/prestashop/PrestaShop8.png';
import Prestashop9 from '../../assets/prestashop/PrestaShop9.png';
import Prestashop10 from '../../assets/prestashop/PrestaShop10.png';
import { Theme, Typography } from '@mui/material';
import { colors } from '../../theme/theme';

export const PrestaShop = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
      ol {
        padding-left: 32px;
      }
      li {
        text-align: left;
      }
      p {
        text-align: left;
        padding-left: 16px;
      }
      a {
        font-family: ${({ theme }: { theme: Theme }) => theme.typography.fontFamily};
        text-decoration: none;
        color: ${colors.secondary};
        font-weight: 500;
        -webkit-text-decoration: underline #f1bfdf 2px;
        text-decoration: underline #f1bfdf 2px;
        text-underline-position: under;
      }
      aside ul {
        padding-left: 16px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className='d-flex flex-row'>
        <div className='sidebar hidden'>
          <aside>
            <ul className='list-inside list-unstyled mt-3'>
              <li>
                <a className='h4'>Presta Shop</a>
                <ul className='list-inside list-unstyled'>
                  <li className='sidebar-link-item'>
                    <a href='#1'>Βήμα 1: Εγκατάσταση</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#2'>Βήμα 2: Παραμετροποίηση plugin</a>
                  </li>
                  <li className='sidebar-link-item'>
                    <a href='#3'>Βήμα 3: Διαμόρφωση Κόστους</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#4'>Βήμα 4: Επεξεργασία Παραγγελίας</a>
                  </li>
                  <li className='sidebar-link-item pb-3'>
                    <a href='#5'>Υποστήριξη</a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-link-item pb-3'>
                <span className='h5 d-flex align-items-center'>
                  <a
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/eshops');
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className='container'>
          <h2>Presta Shop</h2>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-1.7-refactor-master (small).zip'>
              boxnow_prestashop_1.7.8-1.7.9_small.zip
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-1.7-refactor-master (medium).zip'>
              boxnow_prestashop_1.7.8-1.7.9_medium.zip
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-1.7-refactor-master (large).zip'>
              boxnow_prestashop_1.7.8-1.7.9_large.zip
            </a>
          </div>
          <div className='mt-5'>
            <span className='bolder'>Λήψη:</span>
            <a href='https://prestashopzip.blob.core.windows.net/prestashopzip/prestashop-8-main.zip'>
              prestashop-8.zip
            </a>
          </div>
          <h3 className='mt-5'>Οδηγίες εγκατάστασης</h3>
          <h5 className='mt-5' id='1'>
            Βήμα 1: Εγκατάσταση
          </h5>
          <p>
            Πηγαίνουμε στην ενότητα του Back Office (admin) “Πρόσθετα {'>'} Διαχείριση Προσθέτων”.
            Επάνω δεξιά πατάμε το “Ανεβάστε ένα νέο πρόσθετο”.
          </p>

          <img className='mt-3 mb-3' src={Prestashop1} />

          <p>
            Θα ανοίξει ένα νέο παράθυρο στο οποίο θα επιλέξουμε να ανεβάσουμε ένα νέο αρχείο από τον
            υπολογιστή μας. Επιλέξτε από τον υπολογιστή σας το αρχείο του module{' '}
            <span className='bolder'>“boxnow.zip”</span> και αυτόματα θα αρχίσει η εγκατάσταση του
            module.
          </p>

          <img className='mt-3 mb-3' src={Prestashop2} alt='BoxNow' />

          <h5 className='mt-5' id='2'>
            Βήμα 2: Παραμετροποίηση plugin
          </h5>

          <p>
            Πηγαίνουμε στην ενότητα του Back Office (admin) “Πρόσθετα {'>'} Διαχείριση Προσθέτων”.
            Βρίσκουμε στην λίστα με τα πρόσθετα το πρόσθετο “Boxnow” και πατάμε “Διαμόρφωση”
          </p>

          <img className='mt-3 mb-3' src={Prestashop3} alt='BoxNow' />

          <p>Θα εμφανιστεί μία νέα σελίδα με όλα τα πεδία παραμετροποίηση του πρόσθετου.</p>

          <img className='mt-3 mb-3' src={Prestashop4} alt='BoxNow' />

          <p>
            Αρχίζετε να συμπληρώνεται τα πεδία σύμφωνα με τις πληροφορίες - κωδικούς που σας έχουν
            δοθεί από εμάς:{' '}
          </p>
          <p>
            <span className='bolder'>API URL</span>: γράφετε το url προς το API (π.χ.:
            box-now-zvvzxxssazzq-ew.a.run.app).
          </p>
          <p>
            <span className='bolder'>Client ID</span>: Το client id όπως σας έχει δοθεί (π.χ.
            9d8gg3de-8119-4tea-991e-37ff20eq5e50).
          </p>
          <p>
            <span className='bolder'>Client Secret</span>: γράψτε το client secret όπως σας έχει
            δοθεί (π.χ.{' '}
            <span className='word-break'>
              c1144abc1db1f3186442a4ec3d3e9 53de0105c12759a2d82771a888256659e67f).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Κωδικοί Αποθηκών</span>: εδώ γράφετε την αποθήκη
            σας: <span className='bolder'>ID_Αποθήκης[Τίτλος Αποθήκης]</span>.
          </p>
          <p>
            Όπου <span className='bolder'>ID_Αποθήκης</span> γράφετε τον κωδικό αποθήκης όπως σας
            έχει δοθεί (π.χ. 4999).
          </p>
          <p>
            Όπου <span className='bolder'>[Τίτλος Αποθήκης]</span> γράφετε τον τίτλο που θέλετε για
            την αποθήκη (π.χ. [Κεντρική αποθήκη].
          </p>
          <p>
            Αν έχετε πάνω από μία αποθήκη γράφετε κάθε αποθήκη και τις διαχωρίζεται με κόμμα (π.χ.
            <span className='bolder'> 4099[Κεντρική αποθήκη],4098[Αποθήκη Θεσσαλονίκης]</span>).
          </p>
          <p>
            <span className='bolder'>Partner ID</span>: γράψτε το partner id όπως σας έχει δοθεί
            (π.χ. 101).
          </p>
          <p>
            <span className='bolder'>Email Επικοινωνίας</span>: γράψτε το email όπου θα σας έρχονται
            τα pdf vouchers για να τα εκτυπώσετε.
          </p>
          <p>
            <span className='bolder'>Όνομα Επικοινωνίας</span>: Γράψτε κάποιο όνομα επικοινωνίας για
            τα emails.
          </p>
          <p>
            <span className='bolder'>Τηλέφωνο Επικοινωνίας</span>: Γράψτε κάποιο τηλέφωνο
            επικοινωνίας. Προσοχή! Με κωδικό χώρας μπροστά και χωρίς κενά! (π.χ. +302117800000).
          </p>
          <p>
            <span className='bolder'>Διαμόρφωση Χάρτη</span>: Επιλέξτε πως θέλετε να εμφανίζεται ο
            χάρτης με τους Boxnow Lockers στην σελίδα αγοράς (checkout).
          </p>
          <p>Popup: ο χάρτης θα ανοίγει σε νέο παράθυρο.</p>
          <p>Iframe: ο χάρτης θα ανοίγει μέσα στην σελίδα.</p>
          <p>
            <span className='bolder'>Κείμενο button</span>: αν έχετε επιλέξει popup θα εμφανίζει ένα
            button με το οποίο θα ανοίγει ο χάρτης. Γράψτε τον τίτλο που θέλετε να εμφανίζει το
            button.
          </p>
          <p>
            {' '}
            <span className='bolder'>Χρώμα φόντου button</span>: επιλέξτε το χρώμα του φόντου που
            θέλετε να έχει το παραπάνω button.
          </p>
          <p>
            Αφού συμπληρώσετε όλα τα παραπάνω πατήστε την{' '}
            <span className='bolder'>“Αποθήκευση”</span> κάτω αριστερά για να αποθηκευτούν οι
            ρυθμίσεις σας
          </p>

          <h5 className='mt-5' id='3'>
            Βήμα 3: Διαμόρφωση Κόστους
          </h5>
          <p>
            Με την εγκατάσταση του πρόσθετου θα οριστεί σαν τιμή κόστους η default που έχει οριστεί
            στο prestashop. Για να αλλάξετε τις τιμές πηγαίνετε στην ενότητα
            <span className='bolder'>“Αποστολή” {'>'} “Μεταφορείς”</span> και επιλέγουμε την
            επεξεργασία του BoxNow.
          </p>

          <img className='mt-3 mb-3' src={Prestashop5} alt='BoxNow' />
          <div></div>
          <img className='mt-3 mb-3' src={Prestashop6} alt='BoxNow' />

          <p>
            Στις νέες σελίδες μπορείτε να ορίσετε τις τιμές ανάλογα την τοποθεσία, σύνολο
            παραγγελίας κλπ όπως έχετε κάνει και με τους άλλους μεταφορείς.
          </p>

          <h5 className='mt-5' id='4'>
            Βήμα 4: Επεξεργασία Παραγγελίας
          </h5>
          <p>
            Σε κάθε παραγγελία που έχει επιλεγεί η BoxNow σαν μέθοδος αποστολής εμφανίζεται αριστερά
            ένα νέο block με τις πληροφορίες του Boxnow.
          </p>

          <img className='mt-3 mb-3' src={Prestashop7} alt='BoxNow' />

          <p>
            Όταν η παραγγελία είναι έτοιμη για παραλαβή από την Boxnow επιλέγετε{' '}
            <span className='bolder'>“Αποθήκη”</span> και μετά πατάτε το
            <span className='bolder'>“Δημιουργία Vouchers”</span> για να δημιουργήσει νέα αποστολή
            και να επιστρέψει τον αριθμό της (voucher).
          </p>
          <p>Αν όλα είναι σωστά θα εμφανίσει τον αριθμό voucher.</p>

          <img className='mt-3 mb-3' src={Prestashop8} alt='BoxNow' />

          <p>
            Κλικάροντας πάνω στο αριθμό θα ανοίξει σε νέα σελίδα (ή θα σας ζητήσει να κατεβάσετε) το
            αρχείο PDF με το Voucher το οποίο μπορείτε να εκτυπώσετε.{' '}
          </p>
          <p>
            Αν πατήσετε την “Ακύρωση Voucher” το voucher θα ακυρωθεί και πρέπει να δημιουργήσετε
            νέο. (το voucher θα ακυρωθεί εφόσον δεν έχει παραληφθεί από την Boxnow για αποστολή).{' '}
          </p>
          <p>
            Μπορείτε επίσης να εκτυπώσετε πολλαπλά vouchers για κάθε παραγγελία (ανάλογα τον αριθμό
            προϊόντων) σε περίπτωση που δεν μπορείτε να στείλετε όλα τα προϊόντα με μία αποστολή.
          </p>
          <p>
            {' '}
            Υπάρχει περίπτωση η θυρίδα που έχει επιλέξει ο πελάτης να έχει κάποιο πρόβλημα και να
            μην λειτουργεί. Σε αυτήν την περίπτωση η Boxnow θα σας δώσει ένα νέο lockerID το οποίο
            πρέπει να ενημερώσετε κλικάροντας την “Αλλαγή” δίπλα στο ID Θυρίδας.
          </p>

          <img className='mt-3 mb-3' src={Prestashop9} alt='BoxNow' />
          <p>Στο νέο παράθυρο που θα εμφανιστεί γράψτε το νέο ID θυρίδας και αποθηκεύστε.</p>

          <img className='mt-3 mb-3' src={Prestashop10} alt='BoxNow' />

          <h5 className='mt-5' id='5'>
            Προσοχή:
          </h5>

          <ol>
            <li>Τεστάρετε το plugin μόνο με τα tage Api keys.</li>
            <li>
              Επιλέξτε το stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9, Address: IEPA OΔOΣ 116,
              10447.
            </li>
            <li>
              Για να λάβετε το voucher της παραγγελίας πρέπει η παραγγελία να είναι
              ολοκληρωμένη/πληρωμένη.
            </li>
          </ol>

          <h5 className='mt-5'>Υποστήριξη</h5>
          <p>
            Για οποιαδήποτε ερώτηση για την διασύνδεση σας με την υπηρεσία μας παρακαλώ να καλέστε
            μας στο 2111005330 ή στείλτε μας στο <a href='mailto: ict@boxnow.gr'>ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
