const msalConfig = {
  auth: {
    clientId: "8d0420a2-0a25-4fe6-9eb7-dbd8188ba249",
    authority:
      "https://login.microsoftonline.com/54f08b9b-cf2a-4ed2-b0f5-83214d645705",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export default msalConfig;
