import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import AuthenticationHeader from "components/auth/AuthenticationHeader";
import Footer from "components/footer/Footer";
import SecondFooter from "components/footer/SecondFooter";
import Header from "components/header/Header";
import React, { useEffect, useRef } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { p2pSelector } from "../features/p2pSlice";
import { colors } from "../theme/theme";
import { CsCart } from "./eshops/CsCart";
import { CsCartEn } from "./eshops/CsCartEn";
import { Magento1 } from "./eshops/Magento1";
import { Magento1en } from "./eshops/Magento1en";
import { Magento2 } from "./eshops/Magento2";
import { Magento2en } from "./eshops/Magento2en";
import { OpenCart } from "./eshops/Opencart";
import { OpenCartEn } from "./eshops/OpencartEn";
import PartnerPortal from "./eshops/PartnerPortal";
import PartnerPortalEn from "./eshops/PartnerPortalEn";
import { PrestaShop } from "./eshops/PrestaShop";
import { PrestaShopEn } from "./eshops/PrestaShopEn";
import { Shopify } from "./eshops/Shopify";
import { ShopifyEn } from "./eshops/ShopifyEn";
import TailorMade from "./eshops/TailorMade";
import { WooCommerce } from "./eshops/WooCommerce";
import { WooCommerceEn } from "./eshops/WooCommerceEn";

export const Styles = withTheme(styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #ecece2;

  .app-bar {
    border-radius: 0;
    background-color: #ecece2;
    min-height: 100px;
    max-height: 100px;
  }

  .grecaptcha-badge {
    display: none !important;
  }

  .anchor-cursor {
    cursor: pointer;
    height: 100%;
  }

  .image-nov {
    width: 100%;
  }

  .image-nov-mob {
    width: 100%;

    align-self: center;
    margin-top: 1em;
  }

  .border-caution1 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid rgb(68, 214, 45);
    background-color: rgba(255, 186, 0, 0.1);
  }

  .price-list-caution-content1 {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 600;
    font-size: 0.7rem;
    padding-left: 20px;
    color: #4d3800;
  }

  .price-list-caution1 {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 20px;
    color: rgb(68, 214, 45);
  }

  .warning-image1 {
    background-color: transparent;
    width: 35px;
    height: 35px;
    margin-bottom: -10px;
  }

  .drawer-divider {
    background-color: ${colors.secondary};
  }

  .maxbackground-p2p {
    max-width: 1200px;
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecece2;
  }

  .container-before-counter {
    margin-bottom: 4em;
  }

  .maxbackground-p2p-mob {
    max-width: 1200px;
    margin-top: 4em;
    margin-bottom: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecece2;
  }

  .maxbackground-returns {
    max-width: 1400px;
  }

  .is-enabled {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 20px;
    color: #260563;
  }

  .is-enabled-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 13px;
    color: #260563;
  }

  .is-finished {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 20px;
    color: ${colors.primary};
  }

  .image-papers {
    width: 60px;
    height: 60px;
  }

  .image-papers-md {
    width: 80px;
    height: 80px;
  }

  .is-finished-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 15px;
    color: ${colors.primary};
  }

  .is-disabled {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 20px;
    color: rgba(38, 5, 99, 0.3);
  }

  .is-disabled-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 13px;
    color: rgba(38, 5, 99, 0.3);
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    max-height: 100px;

    @media (max-width: 576px) {
      min-height: 70px;
      max-height: 70px;
    }

    .side-item {
      flex: 1;
      display: flex;
      gap: 8px;
      min-width: -webkit-min-content; /* Workaround to Chrome bug */

      &.language-select {
        flex-direction: row-reverse;

        @media (max-width: 1100px) {
          & > button {
            display: none;
          }
        }
      }

      .nav-toggle {
        display: none;
      }

      .nav-item {
        color: ${colors.textPrimaryLight};

        &:hover {
          background-color: ${colors.primary};
          color: ${colors.onBackground};
        }
      }

      @media (max-width: 1100px) {
        .nav-toggle {
          display: block;
        }

        .nav-item {
          display: none;
        }
      }
    }

    & .not-selected {
      filter: grayscale(1);
    }
  }

  .drawer-accordion {
    margin: 0 !important;

    .MuiAccordionDetails-root {
      padding: 0;

      .title-text {
        padding-left: 12px;
      }
    }
  }

  .loader {
    width: 100%;
    height: calc(100vh - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 100%;
    height: 100%;
    background-color: #ecece2;
    display: flex;
    flex-direction: column;

    .image-container {
      width: 100%;
      max-height: 320px;
      height: 320px;
      min-height: 320px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .secondary-app-bar {
      border-radius: 0;
      border-bottom: 2px solid rgba(38, 5, 99, 0.3);

      .toolbar {
        height: 80px;
        overflow: hidden;

        @media (max-width: 576px) {
          padding: 0;
        }

        .toolbar-stack {
          display: flex;

          .toolbar-item {
            display: flex;
            justify-content: center;
            @media (max-width: 576px) {
              height: 75px;
            }
          }
        }
      }
    }

    .content {
      flex-grow: 1;
      padding: ${({ theme }: { theme: Theme }) => theme.spacing(4, 8)};

      @media (max-width: 576px) {
        padding: ${({ theme }: { theme: Theme }) => theme.spacing(1, 1)};
      }

      .action-button-container {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 300px;
        width: 100%;
        padding: ${({ theme }: { theme: Theme }) => theme.spacing(0, 2)};

        & button svg {
          height: 14px;
        }

        & .MuiTypography-caption {
          text-align: center;
          padding: ${({ theme }: { theme: Theme }) => theme.spacing(2, 1)};
        }
      }
    }
  }

  .boxnow-title {
    text-shadow: 0 0 10px ${colors.primary}, 0 0 20px ${colors.primary},
      0 0 40px ${colors.primary};
  }

  .under-outlet {
    position: relative;
    margin-top: 6em;
  }

  .under-outlet-second {
    position: relative;
    margin-top: -7em;
  }

  .under-outlet-third {
    position: relative;
  }

  .under-outlet-out {
    position: relative;
    margin-top: 1em;
    margin-bottom: 4em;
  }

  .give-margin {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .up-lg {
    background-color: #f3f3ec;
    max-width: 2000px;
    min-height: 533px;
    max-height: 533px;
    margin-top: 4em;
  }

  .addition-toolbar {
    position: relative;
    margin-top: 15px;
    align-self: center;
    min-height: 0;
    max-height: 0;
  }

  .mobHeight {
    min-height: 120px;
  }

  .deskHeight {
    min-height: 250px;
  }

  .match-one-struct-row {
    display: flex;
    flex-direction: row;
  }

  .match-one-struct-col {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 2em;
  }

  .match-one-struct-col-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2em;
  }

  .main-title-desk {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 510px;
  }

  .main-title-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%s;
  }

  .main-title-fonts-desk {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 1.1;
    color: ${colors.secondary};
  }

  .main-title-fonts-desk2 {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 900;
    font-size: 2.5rem;
    text-align: center;
    color: ${colors.secondary};
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.primary};
  }

  .main-title-fonts-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 800;
    font-size: 2.3rem;
    color: ${colors.secondary};
    text-align: center;
    line-height: 1.1;
  }

  .main-title-fonts-mob-border {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 800;
    font-size: 2rem;
    color: ${colors.secondary};
    text-align: center;
    line-height: 1.1;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.primary};
  }

  .under-main-title-margin {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    margin-top: 1em;
  }

  .under-main-title-margin-sec {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    margin-top: 1em;
    text-align: center;
  }

  .under-main-title-margin-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    margin-top: 1em;
    text-align: center;
  }

  .under-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
  }

  .under-main-title-sec {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    text-align: center;
  }

  .under-main-title-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    text-align: center;
  }

  .button-get-started {
    width: 280px;
    height: 60px;
    background-color: ${colors.secondary};
    border-radius: 0;
    margin-top: 1em;
    background-color: rgb(68, 214, 45);
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .button-get-started-second {
    width: 280px;
    height: 60px;
    border-radius: 0;
    margin-top: 15em;
    background-color: rgb(68, 214, 45);
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .button-get-started-third {
    width: 280px;
    height: 60px;
    border-radius: 0;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: rgb(68, 214, 45);
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .div-human-locker-desk {
    display: flex;
    margin-left: 35px;
    margin-top: 0;
  }

  .div-human-locker-mob {
    display: flex;
    margin-top: 6em;
  }

  .image-human {
    width: 190px;
    z-index: 50;
  }

  .image-locker {
    width: 460px;
  }

  .image-locker-mob {
    width: 280px;
    height: 250px;
    align-self: center;
    margin-top: 1em;
  }

  .div-image-locker {
    margin-top: -30px;
    margin-left: -60px;
  }

  .div-ref-table {
    align-self: center;
    margin-bottom: 30px;
    max-width: 1200px;
  }

  .main-paper {
    border-radius: 0;
    border: 2px solid rgba(38, 5, 99, 0.3);
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .main-paper-mob {
    border-radius: 0;
    border: 2px solid rgba(38, 5, 99, 0.3);
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .button-transparent {
    background-color: transparent;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    &:hover {
      background-color: transparent;
    }
  }

  .button-transparent-xs {
    background-color: transparent;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    &:hover {
      background-color: transparent;
    }
  }

  .divider-toolbar {
    width: 5%;
    background-color: rgba(38, 5, 99, 0.3);
    margin: 0px !important;
  }

  .triple {
    margin-bottom: 10em;
  }

  .triple-lg {
    margin-bottom: -3em;
  }

  .sub-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 3rem;
    color: ${colors.secondary};
  }

  .sub-main-first-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: #7d7986;
    margin-top: 1em;
  }

  .sub-main-second-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: #7d7986;
  }

  .div-triple-images-desk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2em;
    z-index: 100;
  }

  .div-triple-images-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    z-index: 100;
  }

  .time-paper-desk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: 350px;
    margin-bottom: 0;
    height: 268px;
    padding: 20px;
  }

  .time-paper-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: 300px;
    margin-bottom: 2em;
    height: 268px;
    padding: 40px;
  }

  .time-paper-md {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: calc(100vw - 450px);
    margin-bottom: 2em;
    height: 468px;
    padding: 40px;
  }

  .time-paper-sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: calc(100vw - 200px);
    margin-bottom: 2em;
    height: 468px;
    padding: 40px;
  }

  .time-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.4rem;
    color: ${colors.secondary};
    text-align: center;
  }

  .time-main-title-md {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 2.5rem;
    color: ${colors.secondary};
    text-align: center;
  }

  .time-secondary-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.875rem;
    color: #7d7986;
    text-align: center;
  }

  .time-secondary-title-md {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1.5rem;
    color: #7d7986;
    text-align: center;
  }

  .fast-paper-desk {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    border-radius: 0;
    margin-right: 30px;
    margin-bottom: 0;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: 380px;
    height: 268px;
    padding: 20px;
  }

  .fast-paper-md {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    border-radius: 0;
    margin-right: 0;
    margin-bottom: 2em;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: calc(100vw - 450px);
    height: 468px;
    padding: 40px;
  }

  .fast-paper-sm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    border-radius: 0;
    margin-right: 0;
    margin-bottom: 2em;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: calc(100vw - 200px);
    height: 468px;
    padding: 40px;
  }

  .fast-paper-mob {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    border-radius: 0;
    margin-right: 0;
    margin-bottom: 2em;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: 300px;
    height: 268px;
    padding: 40px;
  }

  .money-paper-desk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: 380px;
    height: 268px;
    padding: 20px;
  }

  .money-paper-md {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: calc(100vw - 450px);
    height: 468px;
    padding: 40px;
  }

  .money-paper-sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: calc(100vw - 200px);
    height: 468px;
    padding: 40px;
  }

  .money-paper-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid rgba(38, 5, 99, 0.3);
    width: 300px;
    height: 268px;
    padding: 40px;
  }

  .pink-div-desk {
    position: absolute;
    background-color: #f1bfdf;
    width: 1130px;
    height: 268px;
    margin-left: 12em;
    margin-top: 15em;
  }

  .pink-div-mob {
    position: absolute;
    background-color: #f1bfdf;
    width: 160px;
    height: 700px;
    margin-left: 0;
    margin-top: 15em;
  }

  .container-section-color {
    background-color: #f3f3ec;
  }

  .container-section-colors {
    background-color: #f3f3ec;
  }

  .section-count {
    background-color: #f3f3ec;
    min-height: 533px;
    margin-top: 4em;
  }

  .section-count-below {
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
  }

  .div-count {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
  }

  .div-lower-md-desk {
    display: flex;
    flex-direction: row;
  }

  .div-lower-md-mob {
    display: flex;
    flex-direction: column;
  }

  .div-count-flex {
    display: flex;
  }

  .div-count-flex-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .count-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2rem;
    color: ${colors.secondary};
  }

  .guide-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.2;
    color: ${colors.secondary};
  }

  .count-main-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    color: #7d7986;
  }

  .locker-button {
    border-radius: 0;
    background-color: #f1bfdf;
    padding: 16px;
    margin-top: 1em;
    width: 182px;
    height: 53px;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .locker-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: ${colors.secondary};
  }

  .locker-typo-second {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: white;
  }

  .send-parcel {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.25rem;
    color: white;
  }

  .locker-div-image {
    margin-top: 1em;
  }

  .locker-image {
    width: 530px;
    height: 375px;
  }

  .locker-image-mob {
    width: 340px;
    height: 240px;
  }

  .cont-track {
    background-color: #ecece2;
  }

  .section-track {
    background-color: #ecece2;
    min-height: 533px;
  }

  .section-news {
    background-color: #ecece2;
  }

  .section-track-below {
    max-width: 1200px;
    background-color: #ecece2;
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .pos-track-title {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }

  .track-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.25rem;
    color: ${colors.secondary};
  }

  .track-main-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    color: #7d7986;
  }

  .track-button {
    border-radius: 0;
    background-color: #f1bfdf;
    padding: 16px;
    margin-top: 1em;
    width: 230px;
    height: 53px;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .track-button-second {
    border-radius: 0;
    background-color: rgb(68, 214, 45);
    padding: 16px;
    margin-top: 1em;
    width: 230px;
    height: 53px;
    &:hover {
      background-color: rgb(62, 204, 40);
    }
    margin-left: 5px;
  }

  .track-button-second-lg {
    border-radius: 0;
    background-color: rgb(68, 214, 45);
    padding: 16px;
    margin-top: 1em;
    width: 230px;
    height: 53px;
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .track-button-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: ${colors.secondary};
  }

  .section-question-div {
    background-color: #f3f3ec;
  }

  .section-question-grid {
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    margin: auto;
  }

  .section-question-grid-div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
  }

  .questions-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.25rem;
    margin-bottom: 1em;
    color: ${colors.secondary};
  }

  .accordion-settings {
    border-radius: 0;
    border-top: 1px solid rgba(38, 5, 99, 0.3);
    background-color: #f3f3ec;
    padding: 20px;
  }

  .accord-summ {
    border-bottom: 1px solid rgba(38, 5, 99, 0.3);
  }

  .accordion-settings-menu {
    border-radius: 0;
    border-top: 1px solid rgba(38, 5, 99, 0.3);
    background-color: #f3f3ec;
    padding: 5px;
    margin: 0;
  }

  .remove-icon {
    color: #6cd04e;
    font-size: 1.5rem;
    margin-top: 3px;
  }

  .add-icon {
    color: #6cd04e;
    font-size: 1.5rem;
    margin-top: 3px;
  }

  .questions-header {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    padding-left: 1em;
    color: ${colors.secondary};
  }

  .guide-main-header {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .accordion-details {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    padding-left: 3em;
    color: #7d7986;
  }

  .section-div-desk {
    min-width: 1200px;
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .image-desk-margin {
    margin-top: -100px;
  }

  .section-track-below-desk {
    min-width: 1200px;
    max-width: 1200px;
    background-color: #ecece2;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: auto;
  }

  .section-track-flex {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
  }

  .section-question-div-flex {
    min-width: 1200px;
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    margin: auto;
  }

  .section-question-div-column {
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    max-width: 1200px;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .f-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -1px;
  }

  .lang-div {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${colors.secondary};
  }

  .mob-menu-div {
    background-color: white;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.secondary};
  }

  .t-div {
    display: flex;
    justify-content: flex-start;
  }

  .header-buttons {
    width: 67.5px;
    height: 60px;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;

    &:hover {
      background-color: white;
    }
  }

  .header-buttons-mob {
    width: 69px;
    height: 60px;
    border-radius: 0;
    border-left: 1px solid ${colors.secondary};
    border-right: 1px solid ${colors.secondary};
    background-color: #ecece2;

    &:hover {
      background-color: white;
    }
  }

  .header-buttons-mob-active {
    width: 69px;
    height: 60px;
    border-radius: 0;
    border-left: 1px solid ${colors.secondary};
    border-right: 1px solid ${colors.secondary};
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .header-buttons-active {
    width: 67.5px;
    height: 60px;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .header-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
  }

  .image-header {
    border: 1px solid ${colors.secondary};
  }

  @media screen and (min-width: 1200px) {
    .image-header {
      border: 1px solid ${colors.secondary};
      height: 116px !important;
    }
  }

  .header-div-buttons {
    width: 100%;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;

    &:hover {
      background-color: white;
    }
  }

  .s-div {
    width: 100%;
    display: flex;
    margin-bottom: -1px;
  }

  .s-f-div {
    width: 185px;
    margin-top: -60px;
  }

  .s-s-div {
    display: flex;
    width: 100%;
    position: relative;
  }

  .s-t-div {
    width: 179px;
  }

  .menu-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
    text-align: center;
  }

  .menu-typo-s {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .menu-typo-s-menu {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.875rem;
    color: ${colors.secondary};
    text-align: center;
  }

  .navigation-mob {
    width: 100%;
    position: relative;
    border-bottom: 1px solid ${colors.secondary};
    background-color: #ecece2;

    display: inline-block;
  }

  .navigation-mob-active {
    width: 100%;
    position: relative;

    background-color: white;

    display: inline-block;
  }

  .navigation-mob a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation-mob-active a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;
    margin-bottom: 7px;
    display: inline-block;
  }

  .navigation-active {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: white;
    margin-bottom: 7px;
    display: inline-block;
  }

  .navigation-content {
    border: 1px solid ${colors.secondary};
    border-top: 0;
    display: none;
    position: absolute;
    background-color: #ecece2;
    width: 100%;
    z-index: 1;
  }
  .navigation a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation-active a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation a:hover {
    background-color: white;
  }

  .navigation-mob a:hover {
    background-color: white;
  }

  .navigation-active a:hover {
    background-color: white;
  }

  .navigation-mob-active a:hover {
    background-color: white;
  }

  .navigation:hover .navigation-content {
    display: block;
  }

  .navigation-mob:hover .navigation-content {
    display: block;
  }

  .navigation-active:hover .navigation-content {
    display: block;
  }

  .navigation-mob-active:hover .navigation-content {
    display: block;
  }

  .navigation-s {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;
    margin-bottom: 7px;
    border-right: 0;
    border-left: 0;
    display: inline-block;
  }

  .navigation-s-active {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: white;
    border-right: 0;
    border-left: 0;
    margin-bottom: 7px;
    display: inline-block;
  }

  .navigation-content {
    border: 1px solid ${colors.secondary};
    border-top: 0;
    display: none;
    position: absolute;
    background-color: white;
    width: 300.1%;
    z-index: 1;
  }
  .navigation-s a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation-s-active a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation-s a:hover {
    background-color: white;
  }

  .navigation-s-active a:hover {
    background-color: white;
  }

  .navigation-s:hover .navigation-content {
    display: block;
  }

  .navigation-s-active:hover .navigation-content {
    display: block;
  }

  .text-dec {
    text-decoration: none;
  }

  .container-mob {
    display: flex;
    flex-direction: column;
  }

  .appbar-d {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .appbar-icons {
    width: 37px;
    height: 37px;
    color: ${colors.secondary};
  }

  .appbar-sec {
    display: flex;
    flex-direction: column;
  }

  .menu-header-div {
    position: absolute;
    width: 100%;
    margin-top: 55px;
    z-index: 100;
  }

  .menu-header-paper {
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    margin-top: 3.5px;
    min-height: 259px;
    max-height: 259px;
  }

  .menu-header-a {
    display: flex;
    text-decoration: none;
    align-items: center;
    margin-top: 10px;
  }

  .menu-header-a-div {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-header-typo {
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
  }

  .contact-header-typo-mob {
    padding-left: 15px;
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
  }

  .contact-text-typo-mob {
    padding-left: 15px;
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .see-details-button-mob {
    margin-left: 15px;
    margin-top: 10px;
    min-width: 180px;
    max-width: 180px;
    height: 44px;
    background-color: #f1bfdf;
    border-radius: 0;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .contact-a-link {
    text-decoration-color: #f1bfdf;
  }

  .contact-text-typo {
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .see-details-button {
    margin-top: 10px;
    min-width: 180px;
    max-width: 180px;
    height: 44px;
    background-color: #f1bfdf;
    border-radius: 0;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .see-details-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }
`);

const MainPage: React.FC = () => {
  const location = useLocation();

  const matchMagento1 = useMatch("/eshops/magento1");
  const matchOpenCart = useMatch("/eshops/opencart");
  const matchCsCart = useMatch("/eshops/cscart");
  const matchPrestaShop = useMatch("/eshops/prestashop");
  const matchWooCommerce = useMatch("/eshops/woocommerce");
  const matchShopify = useMatch("/eshops/shopify");
  const matchMagento2 = useMatch("/eshops/magento2");
  const matchTailorMade = useMatch("/tailor-made");
  const matchPartnerPortal = useMatch("/partner-portal");

  const scrollRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const { locale } = useAppSelector(p2pSelector);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location, scrollRef]);

  return (
    <Styles ref={scrollRef}>
      <Header footerRef={footerRef} />
      <AuthenticationHeader />
      <div className="mt-5">
        {matchMagento1 && (locale === "el" ? <Magento1 /> : <Magento1en />)}
        {matchMagento2 && (locale === "el" ? <Magento2 /> : <Magento2en />)}
        {matchOpenCart && (locale === "el" ? <OpenCart /> : <OpenCartEn />)}
        {matchCsCart && (locale === "el" ? <CsCart /> : <CsCartEn />)}
        {matchPrestaShop &&
          (locale === "el" ? <PrestaShop /> : <PrestaShopEn />)}
        {matchWooCommerce &&
          (locale === "el" ? <WooCommerce /> : <WooCommerceEn />)}
        {matchShopify && (locale === "el" ? <Shopify /> : <ShopifyEn />)}
        {matchPartnerPortal &&
          (locale === "el" ? <PartnerPortal /> : <PartnerPortalEn />)}
        {matchTailorMade && <TailorMade />}
      </div>
      <div>
        <div ref={footerRef}></div>
        <Footer />
        <SecondFooter />
      </div>
    </Styles>
  );
};

export default MainPage;
