import { ClickAwayListener } from "@mui/base";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

const ClipboardButton = (props: { value: string }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const copyToClipBoard = (value: string) => {
    navigator.clipboard.writeText(value);
    handleTooltipOpen();
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title="Copied to clipboard"
        placement="right"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={open}
      >
        <IconButton onClick={() => copyToClipBoard(props.value)}>
          <ContentPasteIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ClipboardButton;
