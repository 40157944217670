import { Container, Paper, Theme } from "@mui/material";
import { colors } from "theme/theme";
import LoggedUserInfo from "./LoggedUserInfo";
import LogoutButton from "./LogoutButton";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";

const Styles = withTheme(styled(Container)`
  .MuiPaper-root {
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    margin: ${({ theme }: { theme: Theme }) => theme.spacing(2, 0, 4, 0)};
    padding: ${({ theme }: { theme: Theme }) => theme.spacing(2)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      flex-direction: column;
      gap: 2rem;
    }
  }
`);

const AuthenticationHeader = () => {
  return (
    <Styles>
      <Paper>
        <LoggedUserInfo />
        <LogoutButton />
      </Paper>
    </Styles>
  );
};
export default AuthenticationHeader;
