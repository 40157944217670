import React, { useRef, useEffect } from "react";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Container,
  Icon,
  Stack,
  Theme,
  Typography,
  TextField,
  MenuItem,
  Button,
  Divider,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import { p2pSelector } from "../../features/p2pSlice";
import useTranslation from "../../translations/useTranslation";
import { colors } from "../../theme/theme";
import emailjs from "@emailjs/browser";

const Styles = withTheme(styled.div`
  background-color: ${colors.primary};
  width: 100%;

  .formmessage {
    margin-top: 1em;
  }

  .phone-row {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
  }

  .email-form {
    margin-top: 1em;
  }

  .first-div-column {
    display: flex;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footer-first {
      width: 50%;
      margin: ${({ theme }: { theme: Theme }) => theme.spacing(2, 0)};
      display: flex;
      flex-direction: column;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        width: 80%;
        justify-content: space-around;
      }
    }

    .first-main-title {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1em;
      color: ${colors.secondary};
    }

    .first-main-title-center {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1em;
      color: ${colors.secondary};

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        font-size: 1.25rem;
      }
    }

    .footer-second {
      width: 50%;
      margin: ${({ theme }: { theme: Theme }) => theme.spacing(2, 0)};
      display: flex;
      justify-content: space-around;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        width: 80%;
        justify-content: flex-start;
      }

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
        justify-content: space-between;
      }
    }

    .div-second {
      width: 30%;
      display: flex;
      flex-direction: column;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        width: 40%;
      }
    }

    .div-third {
      width: 30%;
      display: flex;
      flex-direction: column;

      ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
        width: 40%;
      }
    }
  }

  .form-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .form-typo-center {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.8rem;
    color: ${colors.secondary};
  }

  .text-row {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      flex-direction: column;
    }
  }

  .text-area {
    padding: 2px;
    border: 1px solid ${colors.secondary};
  }

  .text-break {
    display: flex;
    flex-direction: column;
  }

  .phone-code {
    width: 80%;
  }

  .subject {
    width: 100%;
  }

  .contact-button {
    border-radius: 0;
    background-color: #f1bfdf;
    width: 136px;
    height: 48px;
    color: ${colors.secondary};
    align-self: flex-end;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      margin-top: 5px;
    }
  }

  .contact-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .divider-color {
    background-color: ${colors.secondary};
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .footer-row {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .last-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      flex-direction: column;
    }
  }

  .image-settings {
    width: 40px;
    height: 40px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .icon-area {
    padding: 0;
    gap: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
  }

  .contact-text-typos {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 0.8rem;
    color: ${colors.secondary};
  }

  .contact-a-links {
    text-decoration-color: ${colors.secondary};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      padding-bottom: 10px;
    }
  }

  .grecaptcha-badge {
    display: none !important;
  }
`);

const Footer: React.FC = () => {
  const { footerOptions, phoneCodes, footerIconOptions } =
    useAppSelector(p2pSelector);

  const { t } = useTranslation();
  const form = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [phoneCode, setPhoneCode] = React.useState(t("code.phone"));
  const [subject, setSubject] = React.useState(t("theme.message"));
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [snack, setSnack] = React.useState("");
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const formSubjects = [
    t("subject.eshop"),
    t("subject.locker"),
    t("subject.courier"),
    t("subject.job"),
    t("subject.other"),
  ];
  const captchaRef = useRef(null);

  useEffect(() => {
    setSubject(t("theme.message"));
  }, [t]);

  useEffect(() => {
    setPhoneCode(t("code.phone"));
  }, [t]);

  const sendEmail = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      companyName !== "" &&
      phoneCode !== t("code.phone") &&
      phoneNumber !== "" &&
      message !== "" &&
      subject !== t("theme.message")
    ) {
      //@ts-ignore
      captchaRef.current.executeAsync().then(() => {
        emailjs
          .sendForm(
            "service_npviwti",
            "template_hjzo3ox",
            form.current!,
            "nuThkUUU0zmI3kZow"
          )
          .then(
            () => {
              setIsLoading(false);
              setSnack("success");
              setOpenSuccess(true);
              //@ts-ignore
              window.dataLayer.push({
                event: "Form_Submission",
                Service: subject,
              });
              setCompanyName("");
              setPhoneCode(t("code.phone"));
              setSubject(t("theme.message"));
              setPhoneNumber("");
              setMessage("");
              setEmail("");
              //@ts-ignore
              captchaRef.current.reset();
            },
            () => {
              setIsLoading(false);
              setSnack("error");
              setOpenError(true);
            }
          );
      });
    } else {
      setIsLoading(false);
      setSnack("error");
      setOpenError(true);
    }
  };

  return (
    <Styles>
      <Container className="footer-row">
        <div className="first-div-column">
          <div className="footer-first">
            <Typography className="first-main-title">
              {t("communicate.us")}
            </Typography>
            <form ref={form} onSubmit={sendEmail}>
              <TextField
                placeholder={t("name.description")}
                fullWidth
                name="user_name"
                required={true}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <TextField
                placeholder={t("email.address.name")}
                fullWidth
                name="user_email"
                required={true}
                className="email-form"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="phone-row">
                <TextField
                  select
                  fullWidth
                  required={true}
                  name="phonecode"
                  className="phone-code"
                  value={phoneCode}
                  onChange={(e) => setPhoneCode(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: () => phoneCode,
                  }}
                  sx={{
                    "& [aria-expanded=false]": {
                      color:
                        phoneCode === t("code.phone")
                          ? "#B8B7B7"
                          : "rgb(47,47,47)",
                    },
                    "& [aria-expanded=true]": {
                      color:
                        phoneCode === t("code.phone")
                          ? "#B8B7B7"
                          : "rgb(47,47,47)",
                    },
                  }}
                >
                  {phoneCodes.map((item) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.code}
                      {` (${item.country})`}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  placeholder={t("phone.number.header")}
                  fullWidth
                  required={true}
                  name="phonenumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <TextField
                select
                fullWidth
                required={true}
                name="subject"
                className="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: () => subject,
                }}
                sx={{
                  "& [aria-expanded=false]": {
                    color:
                      subject === t("theme.message")
                        ? "#B8B7B7"
                        : "rgb(47,47,47)",
                  },
                  "& [aria-expanded=true]": {
                    color:
                      subject === t("theme.message")
                        ? "#B8B7B7"
                        : "rgb(47,47,47)",
                  },
                }}
              >
                {formSubjects.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <ReCAPTCHA
                sitekey="6LfXeDYfAAAAANnsglEVwYrU5wVL-7khzCuI5gRb"
                size="invisible"
                ref={captchaRef}
              />
              <TextField
                placeholder={t("form.message.footer")}
                className="formmessage"
                name="message"
                fullWidth
                multiline
                rows={4}
                maxRows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <div className="text-row">
                <div className="text-break">
                  <Typography className="form-typo">
                    {t("submit.form.one")}
                  </Typography>
                  <Typography className="form-typo">
                    {t("submit.form.two")}
                  </Typography>
                </div>
                <Button
                  type="submit"
                  className="contact-button"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography className="contact-typo">
                      {t("submit.footer.form")}
                    </Typography>
                  )}
                </Button>
                {snack === "success" ? (
                  <Snackbar
                    ContentProps={{
                      sx: {
                        fontSize: "18px",
                      },
                    }}
                    open={openSuccess}
                    autoHideDuration={5000}
                    message={t("message.sent")}
                    onClose={() => setOpenSuccess(false)}
                  />
                ) : snack === "error" ? (
                  <Snackbar
                    ContentProps={{
                      sx: {
                        background: "#d32f2f",
                        fontSize: "16px",
                      },
                    }}
                    open={openError}
                    autoHideDuration={5000}
                    message={t("message.error")}
                    onClose={() => setOpenError(false)}
                  />
                ) : null}
              </div>
            </form>
          </div>
          <div className="footer-second">
            <div className="div-second">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "2em",
                }}
              >
                <Typography className="first-main-title-center">
                  {t("customers.typo")}
                </Typography>
                {footerOptions
                  .filter(
                    (item) =>
                      item.group === "Customers" || item.group === "Πελάτες"
                  )
                  .sort((a, b) => a.key - b.key)
                  .map((items) => (
                    <Typography
                      key={items.title}
                      component="a"
                      gutterBottom
                      href={items.link}
                      target="_blank"
                      className="form-typo-center"
                    >
                      {items.title}
                    </Typography>
                  ))}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography className="first-main-title-center">
                  {t("services.typo")}
                </Typography>
                {footerOptions
                  .filter(
                    (item) =>
                      item.group === "Services" || item.group === "Υπηρεσίες"
                  )
                  .sort((a, b) => a.key - b.key)
                  .map((items) => (
                    <Typography
                      key={items.title}
                      component="a"
                      gutterBottom
                      href={items.link}
                      target="_blank"
                      className="form-typo-center"
                    >
                      {items.title}
                    </Typography>
                  ))}
              </div>
            </div>
            <div className="div-third">
              <Typography className="first-main-title-center">
                {t("partners.typo")}
              </Typography>
              {footerOptions
                .filter(
                  (item) =>
                    item.group === "Partners" || item.group === "Συνεργάτες"
                )
                .sort((a, b) => a.key - b.key)
                .map((items) => (
                  <Typography
                    key={items.title}
                    component="a"
                    gutterBottom
                    href={items.link}
                    target="_blank"
                    className="form-typo-center"
                  >
                    {items.title}
                  </Typography>
                ))}
            </div>
          </div>
        </div>
        <Divider className="divider-color" />
        <div className="last-row">
          {footerOptions
            .filter(
              (item) =>
                item.group === "About Us" || item.group === "Σχετικά με εμάς"
            )
            .sort((a, b) => a.key - b.key)
            .map((items) => (
              <Typography
                key={items.title}
                component="a"
                gutterBottom
                href={items.link}
                target="_blank"
                className="form-typo-center"
              >
                {items.title}
              </Typography>
            ))}

          <a href="tel:+302111005304" className="contact-a-links">
            <Typography className="contact-text-typos">
              +30 211-100 5304
            </Typography>
          </a>
          <a href="mailto:info@boxnow.gr" className="contact-a-links">
            <Typography className="contact-text-typos">
              info@boxnow.gr
            </Typography>
          </a>

          <Stack
            className="icon-area"
            direction="row"
            padding={5}
            justifyContent="flex-end"
          >
            {footerIconOptions.map(({ icon, link }) => (
              <Icon key={link} className="icon" component="a" href={link}>
                <img
                  src={`/api/uploads${icon}`}
                  className="image-settings"
                  alt=""
                />
              </Icon>
            ))}
          </Stack>
        </div>
      </Container>
    </Styles>
  );
};

export default Footer;
