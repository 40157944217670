import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./app/store";
import {
  AuthenticationResult,
  PublicClientApplication,
} from "@azure/msal-browser";
import msalConfig from "./config/msal";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

await msalInstance.initialize();

const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
  // Account selection
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

//set the account
msalInstance.addEventCallback((event) => {
  if (event.eventType === "msal:loginSuccess" && event.payload) {
    const authenticationResult = event.payload as AuthenticationResult;
    const account = authenticationResult.account;
    msalInstance.setActiveAccount(account);
  }
});

//App main component
function AppComponent() {
  useEffect(() => {
    msalInstance.handleRedirectPromise().then((response) => {
      if (response && response.account) {
        console.log("Logged in");
      }
    });

    // Check if the user is already signed in
    const account = msalInstance.getActiveAccount();
    if (!account) msalInstance.initialize();
  }, []);

  return <App />;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <AppComponent />
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
