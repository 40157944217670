import React from "react";
import BoxNowContainer from "components/BoxNowContainer";
import StepsForConnection from "components/StepsForConnection";
import { Styles as StylesFromMainPage } from "./MainPage";

export const Guide: React.FC = () => {
  return (
    <BoxNowContainer>
      <StylesFromMainPage>
        <StepsForConnection />
      </StylesFromMainPage>
    </BoxNowContainer>
  );
};
