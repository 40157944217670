import React from "react";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Typography, Theme, Container } from "@mui/material";
import magento1 from "../../assets/magento/magento1.png";
import magento2 from "../../assets/magento/magento2.png";
import magento3 from "../../assets/magento/magento3.png";
import magento4 from "../../assets/magento/magento4.png";
import magento5 from "../../assets/magento/magento5.png";
import magento6 from "../../assets/magento/magento6.png";
import magento7 from "../../assets/magento/magento7.png";
import magento8 from "../../assets/magento/magento8.png";
import magento9 from "../../assets/magento/magento9.png";
import magento10 from "../../assets/magento/magento10.png";
import magento11 from "../../assets/magento/magento11.png";
import { colors } from "../../theme/theme";

export const Magento1 = () => {
  const navigate = useNavigate();

  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
  `);
  return (
    <Styles>
      <div className="d-flex flex-row">
        <div className="hidden sidebar">
          <aside>
            <ul className="list-inside list-unstyled mt-3">
              <li>
                <a className="h4">Magento</a>
                <ul className="list-inside list-unstyled">
                  <li className="sidebar-link-item">
                    <a href="#1">Εγκατάσταση</a>
                  </li>
                  <li className="sidebar-link-item">
                    <a href="#2">Ρυθμίσεις Συστήματος </a>
                  </li>
                  <li className="sidebar-link-item">
                    <a href="#3">Δοκιμή Plugin </a>
                  </li>
                  <li className="sidebar-link-item pb-3">
                    <a href="#4">Έκδοση – Εκτύπωση Voucher </a>
                  </li>
                  <li className="sidebar-link-item pb-3">
                    <a href="#5">Βοήθεια</a>
                  </li>
                  <li className="sidebar-link-item pb-3">
                    <span className="h5 d-flex align-items-center">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/eshops");
                        }}
                      >
                        Πίσω
                      </a>
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
        <div className="container">
          <h2>Magento</h2>
          <div className="mt-5">
            <span className="bolder">Λήψη:</span>{" "}
            <a href="https://prestashopzip.blob.core.windows.net/magento1zip/Boxnow_Magento_1.9 (v1.2).zip">
              Boxnow_Magento_1.9.zip
            </a>
          </div>
          <h3 className="mt-5">Οδηγίες εγκατάστασης</h3>
          <h5 className="mt-5" id="1">
            Βήμα 1: Εγκατάσταση
          </h5>

          <p>
            Η εγκατάσταση του Plugin πραγματοποιείται με τη default και
            συνιστώμενη μέθοδο που εγκαθίστανται τα extension για Magento 1.
            Ακολουθούν τα βήματα όπως αναλύονται και στον επίσημο οδηγό:
          </p>

          <ol className="text-left">
            <li className="testtt">
              Συνδεόμαστε στον Server μέσω <span className="bolder">FTP</span>.
            </li>
            <li>
              Μεταφέρουμε και αποσυμπιέζουμε το αρχείο της επέκτασης στον root
              φάκελο του site.
            </li>
            <li className="pb-3">
              Συνδεόμαστε στο διαχειριστικό του site, μεταβαίνουμε στην ενότητα
              <span className="bolder">
                system {">"} tools {">"} backups
              </span>{" "}
              και προβαίνουμε στη δημιουργία Backup πατώντας τον σύνδεσμο{" "}
              <span className="bolder">«System Backup»</span>.
            </li>

            <img className="mt-3 mb-3" src={magento1} />

            <li>
              Μεταβαίνουμε στην ενότητα{" "}
              <span className="bolder">
                system {">"} tools {">"} Compilations
              </span>{" "}
              και απενεργοποιούμε τα{" "}
              <span style={{ fontWeight: "bold" }}>Compilations</span>.
            </li>
            <li className="pb-3">
              Μεταβαίνουμε στην ενότητα{" "}
              <span className="bolder">System {">"} Cache Management</span> και
              προβαίνουμε σε εκκαθάριση την Προσωρινής Αποθήκευσης. Επιλέγουμε
              όλους τους φακέλους από τη λίστα, διαλέγουμε την επιλογή Refresh
              και κάνουμε κλικ στο κουμπί{" "}
              <span className="bolder">“Submit”</span>.
            </li>

            <img className="mt-3 mb-3" src={magento2} />

            <li>
              Μεταβαίνουμε στην ενότητα{" "}
              <span className="bolder">
                System {">"} Configuration {">"} Advanced
              </span>
              , αναζητούμε το πρόσθετο και το ενεργοποιούμε επιλέγοντας την
              επιλογή <span className="bolder">Enable</span> και κάνοντας κλικ
              στο κουμπί <span className="bolder">“Save Config”</span>.
            </li>
          </ol>

          <h4 className="mt-5 bolder" id="2">
            Ρυθμίσεις Συστήματος
          </h4>

          <p className="mt-5">
            Το Plugin έχει εγκατασταθεί, το επόμενο βήμα είναι να μεταβούμε στην
            ενότητα
            <span className="bolder">
              System {">"} Configuration {">"} Shipping Methods
            </span>{" "}
            του διαχειριστικού περιβάλλοντος όπου θα δούμε πως έχει δημιουργηθεί
            ένας νέος τρόπος αποστολής με το όνομα
            <span className="bolder">«BOX NOW Delivery Service»</span>. Στην
            καρτέλα του υπάρχουν οι ρυθμίσεις
          </p>
          <img className="mt-3 mb-3" src={magento3} />

          <p>
            <span className="bolder">Enable</span>: Yes: Για να ενεργοποιηθεί η
            υπηρεσία ή No: Για να απενεργοποιηθεί η υπηρεσία.
          </p>
          <p>
            <span className="bolder">Shipping Method Name</span>:{" "}
            <span style={{ fontWeight: "bold" }}>"BOX NOW Express </span>-
            ΔΩΡΕΑΝ Παράδοση σε 24ωρο Αυτόματο Μηχάνημα Παραλαβής | Αυθημερόν
            παράδοση εντός Αττικής".
          </p>
          <p>
            <span className="bolder">For selected Countries only</span>:
            Επιλέγουμε τις χώρες που θα εξυπηρετεί η υπηρεσία μας.
          </p>
          <p>
            <span className="bolder">Price</span>: γράφετε τo κόστος χρέωσης της
            υπηρεσίας.
          </p>
          <p>
            <span className="bolder">Free Shipping</span>: γράφετε τo ποσό που
            πάνω από αυτό η υπηρεσία μας δεν θα χρεώνεται.
          </p>
          <p>
            <span className="bolder">API URL</span>: γράφετε το url προς το API
            (π.χ.: box-now-zvvzxxssazzq-ew.a.run.app).
          </p>
          <p>
            <span className="bolder">Client ID</span>: Το client id όπως σας
            έχει δοθεί (π.χ. 9d8gg3de-8119-4tea-991e-37ff20eq5e50).
          </p>
          <p>
            <span className="bolder">Client Secret</span>: γράψτε το client
            secret όπως σας έχει δοθεί (π.χ.
            <span className="text-break">
              c1144abc1db1f3186442a4ec3d3e953de0105c12759a2d82771a888256659e67f
            </span>
            ).
          </p>
          <p>
            <span className="bolder">Warehouse Number</span>: μπορούμε να
            εισάγουμε περισσότερα από 1 σημεία ώστε να έχουμε τη δυνατότητα να
            επιλέξουμε κατά την έκδοση του Voucher. Το κάθε σημείο θα είναι κάτω
            από το προηγούμενο σε ξεχωριστή γραμμή και θα ακολουθεί την εξής
            μορφοποίηση «ΚΩΔΙΚΟΣ ΣΗΜΕΙΟΥ:ΛΕΚΤΙΚΟ ΣΗΜΕΙΟΥ». Ακολουθεί ένα
            παράδειγμα:
          </p>

          <img className="mt-3 mb-3" src={magento4} />

          <p>
            <span style={{ fontWeight: "bold" }}>Partner ID</span>: γράφετε το
            partner id όπως σας έχει δοθεί (π.χ. 123).
          </p>
          <p>
            Eνεργοποιούμε τον τρόπο αποστολής και κάνουμε{" "}
            <span className="bolder">Save</span>.
          </p>
          <p>Μπορούμε να προχωρήσουμε σε δοκιμή.</p>

          <h4 className="mt-5 bolder" id="3">
            Δοκιμή Plugin
          </h4>

          <p>
            Κάνουμε δοκιμαστική αγορά επιλέγοντας ως τρόπο αποστολής την επιλογή
            «BOX NOW Delivery Service».
          </p>
          <img src={magento5} />
          <br />
          <br />
          <p>Επιλέγουμε ένα από τα διαθέσιμα Lockers.</p>

          <img className="mt-3 mb-3" src={magento6} />

          <p>Ολοκληρώνουμε την αγορά.</p>

          <img src={magento7} />
          <br />
          <br />

          <h4 className="mt-5 bolder" id="4">
            Έκδοση – Εκτύπωση Voucher
          </h4>

          <p>
            Ως διαχειριστής, μεταβαίνουμε από το μενού του διαχειριστικού στη
            σελίδα
          </p>
          <p>
            <span className="bolder">
              Boxnow '{">"}' Happyonline Boxnow Module
            </span>
            . Εκεί μπορούμε να διαχειριστούμε τις παραγγελίες που έγιναν με τον
            συγκεκριμένο τρόπο αποστολής, να εκδώσουμε τα Vouchers και να τα
            λάβουμε τοπικά.
          </p>

          <img className="mt-3 mb-3" src={magento8} />
          <p>
            Εκδίδουμε Vouchers κάνοντας κλικ στο κουμπί{" "}
            <span className="bolder">«Create Voucher»</span> ορίζοντας και το
            πλήθος που επιθυμούμε. Επίσης μπορούμε να επιλέξουμε και Warehouse
            Number από τη λίστα που εμφανίζεται. Στη στήλη
            <span className="bolder">«Locker ID»</span> βλέπουμε τον αριθμό του
            σημείου παραλαβής που έχει επιλέξει ο πελάτης στην παραγγελία. Αν
            επιθυμούμε να αλλάξουμε το σημείο, μπορούμε να το κάνουμε πατώντας
            επάνω στον αριθμό. Θα εμφανιστεί ένα παράθυρο ώστε να μπορέσουμε να
            επιλέξουμε ένα άλλο σημείο.
          </p>

          <img className="mt-3 mb-3" src={magento9} />
          <p>
            Πλέον βλέπουμε πως το Status για τη συγκεκριμένη παραγγελία έχει τον
            χαρακτηρισμό “Created”. Μπορούμε να εντοπίσουμε το Parcel κάνοντας
            κλικ στον αριθμό του, κάτω από τη στήλη Parcels. Θα μας προωθήσει
            στο εργαλείο εντοπισμού του δέματος της BOX NOW.
          </p>

          <img className="mt-3 mb-3" src={magento10} />
          <p>
            Κάτω από τη στήλη Info υπάρχουν πλέον σύνδεσμοι με τον αριθμό του
            κάθε Voucher όπου κλικάροντάς τους, μπορούμε να τα αποθηκεύσουμε
            τοπικά.
          </p>
          <img className="mt-3 mb-3" src={magento11} />

          <h4 className="mt-5" id="5">
            Σημειώσεις:
          </h4>

          <ol>
            <li>Έλεγχος του προσθέτου με τα stage API keys.</li>
            <li>
              Επιλογή θυρίδας Stage περιβάλλοντος: Aegean ΜΕΤΡΟ Ελαιώνας, locker
              id: 9, Address: IEPA OΔOΣ 116, 10447
            </li>
            <li>
              Όταν ολοκληρώνεται μία νέα παραγγελία θα σας αποστέλλουμε αυτόματα
              ένα PDF αρχείο με την ετικέτα αποστολής.
            </li>
          </ol>

          <h4 className="mt-5">Βοήθεια</h4>
          <p>
            Σε περίπτωση ερωτήσεων αναφορικά με τις λύσεις και τον τρόπο
            υλοποίησης του προσθέτου μας, παρακαλώ απευθυνθείτε στην διεύθυνση
            ηλεκτρονικού ταχυδρομείου:
            <a href="mailto: ict@boxnow.gr">ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
