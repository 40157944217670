import { PropsWithChildren, useEffect, useRef } from "react";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { CircularProgress, Theme } from "@mui/material";
import { useLocation } from "react-router-dom";

import { colors } from "../theme/theme";
import {
  getStrapiData,
  getTranslations,
  p2pSelector,
} from "../features/p2pSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Locale } from "../api/types";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import SecondFooter from "./footer/SecondFooter";
import AuthenticationHeader from "./auth/AuthenticationHeader";

const Styles = withTheme(styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #ecece2;

  .app-bar {
    border-radius: 0;
    background-color: #ecece2;
    min-height: 100px;
    max-height: 100px;
  }

  .anchor-cursor {
    cursor: pointer;
    height: 100%;
  }

  .image-nov {
    width: 100%;
  }

  .image-nov-mob {
    width: 100%;

    align-self: center;
    margin-top: 1em;
  }

  .border-caution1 {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid rgb(68, 214, 45);
    background-color: rgba(255, 186, 0, 0.1);
  }

  .price-list-caution-content1 {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 600;
    font-size: 0.7rem;
    padding-left: 20px;
    color: #4d3800;
  }

  .price-list-caution1 {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 600;
    font-size: 1.1rem;
    padding-left: 20px;
    color: rgb(68, 214, 45);
  }

  .warning-image1 {
    background-color: transparent;
    width: 35px;
    height: 35px;
    margin-bottom: -10px;
  }

  .drawer-divider {
    background-color: ${colors.secondary};
  }

  .container-before-counter {
    margin-bottom: 4em;
  }

  .maxbackground-returns {
    max-width: 1400px;
  }

  .is-enabled {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 20px;
    color: #260563;
  }

  .is-enabled-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 13px;
    color: #260563;
  }

  .is-finished {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 20px;
    color: ${colors.primary};
  }

  .is-finished-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 15px;
    color: ${colors.primary};
  }

  .is-disabled {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 20px;
    color: rgba(38, 5, 99, 0.3);
  }

  .is-disabled-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 13px;
    color: rgba(38, 5, 99, 0.3);
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    max-height: 100px;

    @media (max-width: 576px) {
      min-height: 70px;
      max-height: 70px;
    }

    .side-item {
      flex: 1;
      display: flex;
      gap: 8px;
      min-width: -webkit-min-content; /* Workaround to Chrome bug */

      &.language-select {
        flex-direction: row-reverse;

        @media (max-width: 1100px) {
          & > button {
            display: none;
          }
        }
      }

      .nav-toggle {
        display: none;
      }

      .nav-item {
        color: ${colors.textPrimaryLight};

        &:hover {
          background-color: ${colors.primary};
          color: ${colors.onBackground};
        }
      }

      @media (max-width: 1100px) {
        .nav-toggle {
          display: block;
        }

        .nav-item {
          display: none;
        }
      }
    }

    & .not-selected {
      filter: grayscale(1);
    }
  }

  .drawer-accordion {
    margin: 0 !important;

    .MuiAccordionDetails-root {
      padding: 0;

      .title-text {
        padding-left: 12px;
      }
    }
  }

  .loader {
    width: 100%;
    height: calc(100vh - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .boxnow-title {
    text-shadow: 0 0 10px ${colors.primary}, 0 0 20px ${colors.primary},
      0 0 40px ${colors.primary};
  }

  .under-outlet {
    position: relative;
    margin-top: 6em;
  }

  .under-outlet-second {
    position: relative;
    margin-top: -7em;
  }

  .under-outlet-third {
    position: relative;
  }

  .give-margin {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .up-lg {
    background-color: #f3f3ec;
    max-width: 2000px;
    min-height: 533px;
    max-height: 533px;
    margin-top: 4em;
  }

  .addition-toolbar {
    position: relative;
    margin-top: 15px;
    align-self: center;
    min-height: 0;
    max-height: 0;
  }

  .mobHeight {
    min-height: 120px;
  }

  .deskHeight {
    min-height: 250px;
  }

  .match-one-struct-row {
    display: flex;
    flex-direction: row;
  }

  .match-one-struct-col {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 2em;
  }

  .match-one-struct-col-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2em;
  }

  .main-title-desk {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 510px;
  }

  .main-title-mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%s;
  }

  .under-main-title-margin {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    margin-top: 1em;
  }

  .under-main-title-margin-sec {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    margin-top: 1em;
    text-align: center;
  }

  .under-main-title-margin-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    margin-top: 1em;
    text-align: center;
  }

  .under-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
  }

  .under-main-title-sec {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    text-align: center;
  }

  .under-main-title-mob {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    color: #7d7986;
    text-align: center;
  }

  .button-get-started {
    width: 280px;
    height: 60px;
    background-color: ${colors.secondary};
    border-radius: 0;
    margin-top: 1em;
    background-color: rgb(68, 214, 45);
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .button-get-started-second {
    width: 280px;
    height: 60px;
    border-radius: 0;
    margin-top: 15em;
    background-color: rgb(68, 214, 45);
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .button-get-started-third {
    width: 280px;
    height: 60px;
    border-radius: 0;
    margin-top: 3em;
    margin-bottom: 5em;
    background-color: rgb(68, 214, 45);
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .div-human-locker-desk {
    display: flex;
    margin-left: 35px;
    margin-top: 0;
  }

  .div-human-locker-mob {
    display: flex;
    margin-top: 6em;
  }

  .image-human {
    width: 190px;
    z-index: 50;
  }

  .image-locker {
    width: 460px;
  }

  .image-locker-mob {
    width: 280px;
    height: 250px;
    align-self: center;
    margin-top: 1em;
  }

  .div-image-locker {
    margin-top: -30px;
    margin-left: -60px;
  }

  .div-ref-table {
    align-self: center;
    margin-bottom: 30px;
    max-width: 1200px;
  }

  .main-paper {
    border-radius: 0;
    border: 2px solid rgba(38, 5, 99, 0.3);
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .main-paper-mob {
    border-radius: 0;
    border: 2px solid rgba(38, 5, 99, 0.3);
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .button-transparent {
    background-color: transparent;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    &:hover {
      background-color: transparent;
    }
  }

  .button-transparent-xs {
    background-color: transparent;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    &:hover {
      background-color: transparent;
    }
  }

  .divider-toolbar {
    width: 5%;
    background-color: rgba(38, 5, 99, 0.3);
    margin: 0px !important;
  }

  .triple {
    margin-bottom: 10em;
  }

  .triple-lg {
    margin-bottom: -3em;
  }

  .sub-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 3rem;
    color: ${colors.secondary};
  }

  .sub-main-first-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: #7d7986;
    margin-top: 1em;
  }

  .sub-main-second-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: #7d7986;
  }

  .container-section-color {
    background-color: #f3f3ec;
  }

  .section-count {
    background-color: #f3f3ec;
    min-height: 533px;
    margin-top: 4em;
  }

  .section-count-below {
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
  }

  .div-count {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
  }

  .div-lower-md-desk {
    display: flex;
    flex-direction: row;
  }

  .div-lower-md-mob {
    display: flex;
    flex-direction: column;
  }

  .count-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2rem;
    color: ${colors.secondary};
  }

  .guide-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.2;
    color: ${colors.secondary};
  }

  .count-main-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    color: #7d7986;
  }

  .locker-button {
    border-radius: 0;
    background-color: #f1bfdf;
    padding: 16px;
    margin-top: 1em;
    width: 182px;
    height: 53px;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .locker-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: ${colors.secondary};
  }

  .locker-typo-second {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: white;
  }

  .send-parcel {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.25rem;
    color: white;
  }

  .locker-image {
    width: 530px;
    height: 375px;
  }

  .locker-image-mob {
    width: 340px;
    height: 240px;
  }

  .cont-track {
    background-color: #ecece2;
  }

  .section-track {
    background-color: #ecece2;
    min-height: 533px;
  }

  .section-news {
    background-color: #ecece2;
  }

  .section-track-below {
    max-width: 1200px;
    background-color: #ecece2;
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .pos-track-title {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }

  .track-main-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.25rem;
    color: ${colors.secondary};
  }

  .track-main-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    color: #7d7986;
  }

  .track-button {
    border-radius: 0;
    background-color: #f1bfdf;
    padding: 16px;
    margin-top: 1em;
    width: 230px;
    height: 53px;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .track-button-second {
    border-radius: 0;
    background-color: rgb(68, 214, 45);
    padding: 16px;
    margin-top: 1em;
    width: 230px;
    height: 53px;
    &:hover {
      background-color: rgb(62, 204, 40);
    }
    margin-left: 5px;
  }

  .track-button-second-lg {
    border-radius: 0;
    background-color: rgb(68, 214, 45);
    padding: 16px;
    margin-top: 1em;
    width: 230px;
    height: 53px;
    &:hover {
      background-color: rgb(62, 204, 40);
    }
  }

  .track-button-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.125rem;
    color: ${colors.secondary};
  }

  .accord-summ {
    border-bottom: 1px solid rgba(38, 5, 99, 0.3);
  }

  .accordion-settings-menu {
    border-radius: 0;
    border-top: 1px solid rgba(38, 5, 99, 0.3);
    background-color: #f3f3ec;
    padding: 5px;
    margin: 0;
  }

  .guide-main-header {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .section-div-desk {
    min-width: 1200px;
    max-width: 1200px;
    background-color: #f3f3ec;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .image-desk-margin {
    margin-top: -100px;
  }

  .section-track-below-desk {
    min-width: 1200px;
    max-width: 1200px;
    background-color: #ecece2;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: auto;
  }

  .section-track-flex {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .f-div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -1px;
  }

  .lang-div {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${colors.secondary};
  }

  .mob-menu-div {
    background-color: white;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.secondary};
  }

  .t-div {
    display: flex;
    justify-content: flex-start;
  }

  .header-buttons {
    width: 67.5px;
    height: 60px;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;

    &:hover {
      background-color: white;
    }
  }

  .header-buttons-mob {
    width: 69px;
    height: 60px;
    border-radius: 0;
    border-left: 1px solid ${colors.secondary};
    border-right: 1px solid ${colors.secondary};
    background-color: #ecece2;

    &:hover {
      background-color: white;
    }
  }

  .header-buttons-mob-active {
    width: 69px;
    height: 60px;
    border-radius: 0;
    border-left: 1px solid ${colors.secondary};
    border-right: 1px solid ${colors.secondary};
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .header-buttons-active {
    width: 67.5px;
    height: 60px;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  .header-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
  }

  .image-header {
    border: 1px solid ${colors.secondary};
  }

  .header-div-buttons {
    width: 100%;
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;

    &:hover {
      background-color: white;
    }
  }

  .s-div {
    width: 100%;
    display: flex;
    margin-bottom: -1px;
  }

  .s-f-div {
    width: 185px;
    margin-top: -60px;
  }

  .s-s-div {
    display: flex;
    width: 100%;
    position: relative;
  }

  .s-t-div {
    width: 179px;
  }

  .menu-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
    text-align: center;
  }

  .menu-typo-s {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .menu-typo-s-menu {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 400;
    font-size: 0.875rem;
    color: ${colors.secondary};
    text-align: center;
  }

  .navigation-mob {
    width: 100%;
    position: relative;
    border-bottom: 1px solid ${colors.secondary};
    background-color: #ecece2;

    display: inline-block;
  }

  .navigation-mob-active {
    width: 100%;
    position: relative;

    background-color: white;

    display: inline-block;
  }

  .navigation-mob a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation-mob-active a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;
    margin-bottom: 7px;
    display: inline-block;
  }

  .navigation-active {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: white;
    margin-bottom: 7px;
    display: inline-block;
  }

  .navigation-content {
    border: 1px solid ${colors.secondary};
    border-top: 0;
    display: none;
    position: absolute;
    background-color: #ecece2;
    width: 100%;
    z-index: 1;
  }
  .navigation a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation-active a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation a:hover {
    background-color: white;
  }

  .navigation-mob a:hover {
    background-color: white;
  }

  .navigation-active a:hover {
    background-color: white;
  }

  .navigation-mob-active a:hover {
    background-color: white;
  }

  .navigation:hover .navigation-content {
    display: block;
  }

  .navigation-mob:hover .navigation-content {
    display: block;
  }

  .navigation-active:hover .navigation-content {
    display: block;
  }

  .navigation-mob-active:hover .navigation-content {
    display: block;
  }

  .navigation-s {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: #ecece2;
    margin-bottom: 7px;
    border-right: 0;
    border-left: 0;
    display: inline-block;
  }

  .navigation-s-active {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.secondary};
    background-color: white;
    border-right: 0;
    border-left: 0;
    margin-bottom: 7px;
    display: inline-block;
  }

  .navigation-content {
    border: 1px solid ${colors.secondary};
    border-top: 0;
    display: none;
    position: absolute;
    background-color: white;
    width: 300.1%;
    z-index: 1;
  }
  .navigation-s a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation-s-active a {
    color: black;
    padding: 12px;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .navigation-s a:hover {
    background-color: white;
  }

  .navigation-s-active a:hover {
    background-color: white;
  }

  .navigation-s:hover .navigation-content {
    display: block;
  }

  .navigation-s-active:hover .navigation-content {
    display: block;
  }

  .text-dec {
    text-decoration: none;
  }

  .container-mob {
    display: flex;
    flex-direction: column;
  }

  .appbar-d {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .appbar-icons {
    width: 37px;
    height: 37px;
    color: ${colors.secondary};
  }

  .appbar-sec {
    display: flex;
    flex-direction: column;
  }

  .menu-header-div {
    position: absolute;
    width: 100%;
    margin-top: 55px;
    z-index: 100;
  }

  .menu-header-paper {
    border-radius: 0;
    border: 1px solid ${colors.secondary};
    margin-top: 3.5px;
    min-height: 259px;
    max-height: 259px;
  }

  .menu-header-a {
    display: flex;
    text-decoration: none;
    align-items: center;
    margin-top: 10px;
  }

  .menu-header-a-div {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-header-typo {
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
  }

  .contact-header-typo-mob {
    padding-left: 15px;
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${colors.secondary};
  }

  .contact-text-typo-mob {
    padding-left: 15px;
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .see-details-button-mob {
    margin-left: 15px;
    margin-top: 10px;
    min-width: 180px;
    max-width: 180px;
    height: 44px;
    background-color: #f1bfdf;
    border-radius: 0;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .contact-a-link {
    text-decoration-color: #f1bfdf;
  }

  .contact-text-typo {
    padding-top: 10px;
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .see-details-button {
    margin-top: 10px;
    min-width: 180px;
    max-width: 180px;
    height: 44px;
    background-color: #f1bfdf;
    border-radius: 0;
    &:hover {
      background-color: #f1bfdf;
    }
  }

  .see-details-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.secondary};
  }

  .header-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 2.25rem;
    margin-bottom: 1em;
    color: ${colors.secondary};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("md")} {
      font-size: 2rem;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      font-size: 1.8rem;
    }
  }

  .common-text {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.5rem;
    color: ${colors.secondary};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("md")} {
      font-size: 1.4rem;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      font-size: 1rem;
    }
  }
`);

interface BoxNowContainerProps {}

const BoxNowContainer = (props: PropsWithChildren<BoxNowContainerProps>) => {
  const footerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const scrollRef = useRef<HTMLDivElement>(null);

  const { loading } = useAppSelector(p2pSelector);

  const isEnglishUrl = window.location.pathname.includes("/en");

  useEffect(() => {
    const handleLanguageChange = (locale: Locale) => {
      dispatch(getTranslations(locale));
    };

    if (isEnglishUrl) {
      handleLanguageChange("en");
    } else {
      handleLanguageChange("el");
    }
  }, [dispatch, isEnglishUrl]);

  useEffect(() => {
    if (window.location.pathname.includes("/en")) {
      dispatch(getStrapiData("en"));
      console.log("inside");
    } else {
      dispatch(getStrapiData("el"));
    }
  }, [dispatch]);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location, scrollRef]);

  return (
    <Styles ref={scrollRef}>
      {/* Header */}
      <Header footerRef={footerRef} />

      {/* Authentication */}
      <AuthenticationHeader />

      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {/* Contents */}
          {props.children}

          {/* Footers */}
          <div ref={footerRef}>
            <Footer />
            <SecondFooter />
          </div>
        </div>
      )}
    </Styles>
  );
};

export default BoxNowContainer;
