import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OBFResponse } from "api/types";
import { RootState } from "app/store";

const initialState: OBFResponse = {
  stage: {
    message: "",
    keys: {
      clientId: "",
      clientSecret: "",
    },
    partnerId: "",
    webhookSecret: "",
  },
  prod: {
    message: "",
    keys: {
      clientId: "",
      clientSecret: "",
    },
    partnerId: "",
    webhookSecret: "",
  },
};

const onBoardingFormSlice = createSlice({
  name: "obfState",
  initialState,
  reducers: {
    formSubmit: (state, action: PayloadAction<OBFResponse>) => {
      state.stage = action.payload.stage;
      state.prod = action.payload.prod;
    },
  },
});

export const { formSubmit } = onBoardingFormSlice.actions;

export const onBoardingFormSelector = (state: RootState) => state.obf;

export default onBoardingFormSlice.reducer;
