import React from "react";
import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Opencart1 from "../../assets/opencart/opencart1.png";
import Opencart2 from "../../assets/opencart/OpenCart2.png";
import Opencart3 from "../../assets/opencart/OpenCart3.png";
import Opencart4 from "../../assets/opencart/OpenCart4.png";
import Opencart5 from "../../assets/opencart/OpenCart5.png";
import Opencart6 from "../../assets/opencart/OpenCart6.png";
import Opencart7 from "../../assets/opencart/OpenCart7.png";
import Opencart8 from "../../assets/opencart/OpenCart8.png";
import Opencart10 from "../../assets/opencart/OpenCart10.png";
import Opencart11 from "../../assets/opencart/OpenCart11.png";
import Opencart12 from "../../assets/opencart/opencart12.png";
import { Theme, Typography } from "@mui/material";
import { colors } from "../../theme/theme";

export const OpenCart = () => {
  const navigate = useNavigate();
  const Styles = withTheme(styled.div`
    @media screen and (min-width: 1200px) {
      .sidebar {
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
        border-right: 1px solid gray;
        width: 250px;
      }
    }
    .sidebar-link-item {
      padding-top: 3rem;
      padding-right: 2rem;
      padding-left: 1rem;
      font-size: 14pt;
    }

    @media (max-width: 1200px) {
      .hidden {
        display: none;
      }
    }

    .icon-cont {
      position: absolute;
      top: 76%;
      left: 15%;
      transform: rotate(180deg);
      width: 80px;
      height: 100px;
      cursor: pointer;
    }
    ol {
      padding-left: 32px;
    }
    li {
      text-align: left;
    }
    p {
      text-align: left;
      padding-left: 16px;
    }
    a {
      font-family: ${({ theme }: { theme: Theme }) =>
        theme.typography.fontFamily};
      text-decoration: none;
      color: ${colors.secondary};
      font-weight: 500;
      -webkit-text-decoration: underline #f1bfdf 2px;
      text-decoration: underline #f1bfdf 2px;
      text-underline-position: under;
    }
    aside ul {
      padding-left: 16px;
    }
    img {
      max-width: 100%; /* Ensure images don't exceed the container width */
      height: auto; /* Maintain aspect ratio */
      margin: 0 auto; /* Center images horizontally if needed */
      display: block; /* Remove default image inline alignment */
    }
    .box {
      display: none;
    }

    .download-link {
      display: block;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    #trigger:checked + .box {
      display: block;
    }
    #trigger2:checked + .box {
      display: block;
    }
  `);
  return (
    <Styles>
      <div className="d-flex flex-row">
        <div className="sidebar hidden">
          <aside>
            <ul className="list-inside list-unstyled mt-3">
              <li>
                <a className="h4">Open Cart</a>
                <ul className="list-inside list-unstyled">
                  <li className="sidebar-link-item">
                    <a href="#1">Βήμα 1: Εγκατάσταση και διαχείριση</a>
                  </li>
                  <li className="sidebar-link-item">
                    <a href="#2">Βήμα 2: Παραμετροποίηση plugin</a>
                  </li>
                  <li className="sidebar-link-item">
                    <a href="#3">Βήμα 3: Επεξεργασία Παραγγελίας</a>
                  </li>
                  <li className="sidebar-link-item pb-3">
                    <a href="#Σημειωσεις">Υποστήριξη</a>
                  </li>
                </ul>
              </li>
              <li className="sidebar-link-item pb-3">
                <span className="h5 d-flex  align-items-center">
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/eshops");
                    }}
                  >
                    Πίσω
                  </a>
                </span>
              </li>
            </ul>
          </aside>
        </div>
        <div className="container">
          <h2>Open Cart</h2>
          <div className="mt-5">
            <span>
              Για τη λήψη και την υποστήριξη του plugin επικοινωνήστε με την :
            </span>
            <a href="https://www.design-solutions.gr/" target="_blank">
              design-solutions.gr
            </a>
          </div>
          <div className="mt-5">
            <span>
              Για περαιτέρω πληροφορίες παρακαλώ απευθυνθείτε στην διεύθυνση
              ηλεκτρονικού ταχυδρομείου:
              <a href="mailto: info@design-solutions.gr">
                info@design-solutions.gr
              </a>
            </span>
          </div>
          <div className="mt-5">
            <span className="bolder">Λήψη OpenCart v2:</span>
            <input id="trigger" type="checkbox" />
            <div className="box mt-3 mb-3">
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.2.1.x_for_journal2.x.ocmod (1).zip">
                  boxnow.2.1.x_for_journal2.x.zip
                </a>
              </div>
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.2.1.x.ocmod (1).zip">
                  boxnow.2.1.x.zip
                </a>
              </div>
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.2.3.x_for_journal2.x.ocmod (1).zip">
                  boxnow.2.3.x_for_journal2.x.zip
                </a>
              </div>
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.2.3.x_for_journal3.ocmod.zip">
                  boxnow.2.3.x_for_journal3.x.zip
                </a>
              </div>
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.2.3.x.ocmod (1).zip">
                  boxnow.2.3.x.zip
                </a>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <span className="bolder">Λήψη OpenCart v3:</span>
            <input id="trigger2" type="checkbox" />{" "}
            {/* Note: Changed the id attribute */}
            <div className="box mt-3 mb-3">
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.3.x.x_for_best_checkout.zip">
                  boxnow.3.x.x_for_best_checkout.zip
                </a>
              </div>
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.3.x.x_for_journal3.ocmod (1).zip">
                  boxnow.3.x.x_for_journal3.zip
                </a>
              </div>
              <div className="download-link">
                <a href="https://prestashopzip.blob.core.windows.net/opencartzip/boxnow.3.x.x.ocmod (1).zip">
                  boxnow.3.x.x.zip
                </a>
              </div>
            </div>
          </div>

          <h3 className="mt-5">Οδηγίες εγκατάστασης</h3>
          <h5 className="mt-5" id="1">
            Βήμα 1: Εγκατάσταση και διαχείριση
          </h5>
          <p>
            Για να εγκαταστήσουμε το plugin στο διαχειριστικό μας πηγαίνουμε
            <span className="bolder">
              Επεκτάσεις -{">"} Εγκατάσταση Επεκτάσεων
            </span>
            .
          </p>

          <img src={Opencart1} />

          <p>
            Στη συνέχεια ανεβάζουμε το zip αρχείο και η εγκατάσταση
            ολοκληρώνεται.
          </p>
          <img src={Opencart2} />
          <p>
            Πλέον έχουμε την καρτέλα{" "}
            <span className="bolder">BoxNow Delivery Services</span>.
          </p>
          <img src={Opencart3} />

          <h5 className="mt-5" id="2">
            Βήμα 2: Παραμετροποίηση plugin
          </h5>
          <p>
            Στη συνέχεια πηγαίνουμε από το διαχειριστικό μας Επεκτάσεις -{">"}{" "}
            Επεκτάσεις -{">"} Αποστολές -{"->"} BoxNow Delivery Services και
            εμφανίζεται το παρακάτω menu για να αρχίζετε να συμπληρώνεται τα
            πεδία σύμφωνα με τις πληροφορίες - κωδικούς που σας έχουν δοθεί από
            εμάς:{" "}
          </p>
          <p>
            <span className="bolder">Cost</span>: γράφετε τo κόστος χρέωσης της
            υπηρεσίας.
          </p>
          <p>
            <span className="bolder">Free Shipping</span>: γράφετε τo ποσό που
            πάνω από αυτό η υπηρεσία μας δεν θα χρεώνεται.{" "}
          </p>
          <p>
            <span className="bolder">API URL</span>: γράφετε το url προς το API
            (π.χ.: box-now-zvvzxxssazzq-ew.a.run.app).
          </p>
          <p>
            <span className="bolder">Client ID</span>: Το client id όπως σας
            έχει δοθεί (π.χ. 9d8gg3de-8119-4tea-991e-37ff20eq5e50).
          </p>
          <p className="text-break">
            <span className="bolder">Client Secret</span>: γράψτε το client
            secret όπως σας έχει δοθεί (π.χ.{" "}
            <span className="break-word">
              d3e953de0105c12759a2d82771a888256659e67f).
            </span>
          </p>
          <p>
            <span className="bolder">Κωδικοί Αποθηκών</span>: εδώ γράφετε την
            αποθήκη σας:
            <span className="bolder">ID_Αποθήκης [Τίτλος Αποθήκης]</span>
          </p>
          <p>
            Όπου<span className="bolder"> ID_Αποθήκης </span>γράφετε τον κωδικό
            αποθήκης όπως σας έχει δοθεί (π.χ. 4999).
          </p>
          <p>
            Όπου<span className="bolder"> [Τίτλος Αποθήκης] </span>γράφετε τον
            τίτλο που θέλετε για την αποθήκη (π.χ. [Κεντρική αποθήκη].)
          </p>

          <img src="/media/OpenCart4.png" />

          <p>
            <span className="bolder">Partner ID</span>: γράφετε το partner id
            όπως σας έχει δοθεί (π.χ. 123).
          </p>
          <p>
            <span className="bolder">Use only on specific payment methods</span>
            : τσεκάρετε τους τρόπους πληρωμής που θέλουμε να υποστηρίζει η
            υπηρεσία.
          </p>
          <p>
            <span className="bolder">Tax className</span>: γράφετε το ΦΠΑ.{" "}
          </p>
          <p>
            <span className="bolder">Geo Zone</span>: επιλέγετε τις γεωγραφικές
            ζώνες που θα εμφανίζεται η υπηρεσία.
          </p>
          <p>
            <span className="bolder">Status</span>: Enable: όταν είναι
            ενεργοποιημένο το plugin ή disabled: όταν είναι απενεργοποιημένο.
          </p>
          <p>
            Αφού συμπληρώσετε όλα τα παραπάνω πατήστε την{" "}
            <span className="bolder">“Αποθήκευση”</span> πάνω δεξιά για να
            αποθηκευτούν οι ρυθμίσεις σας.
          </p>

          <img src={Opencart5} />
          <img src={Opencart6} />

          <h5 className="mt-5" id="3">
            Βήμα 3: Επεξεργασία Παραγγελίας
          </h5>
          <p>
            Στη συνέχεια επιλέγουμε την καρτέλα <span className="bolder"></span>
            BoxNow Delivery Services και εμφανίζονται οι παρακάτω επιλογές.
          </p>

          <img src={Opencart7} />

          <p>
            Με το κουμπί <span className="bolder">Εμφάνισης</span> μπορούμε να
            δούμε την παραγγελία που θα επιλέξουμε.
          </p>
          <img src={Opencart8} />
          <p>
            Στον <span className="bolder">Αριθμό Δεμάτων</span> κάνοντας κλικ
            πάνω στον αριθμό, μας ανοίγει κατευθείαν την σελίδα εντοπισμού του
            δέματος.
          </p>

          <img src={Opencart12} />
          <p>
            Στην <span className="bolder">Κατάσταση BoxNow</span> βλέπουμε την
            κατάσταση του κάθε αριθμού Voucher, αν έχει δημιουργηθεί, ή αν
            χρειάζεται να δημιουργηθεί.
          </p>
          <img src={Opencart11} />

          <p>
            Στην περίπτωση που πρέπει να δημιουργηθεί, το δημιουργούμε
            επιλέγοντας το κουμπί Δημιουργία Voucher που βλέπουμε στην παραπάνω
            εικόνα, ορίζοντας και το πλήθος που επιθυμούμε. Επίσης μπορούμε να
            επιλέξουμε και Warehouse Number από τη λίστα που εμφανίζεται. Στη
            στήλη «Locker ID» βλέπουμε τον αριθμό του σημείου παραλαβής που έχει
            επιλέξει ο πελάτης στην παραγγελία. Αν επιθυμούμε να αλλάξουμε το
            σημείο, μπορούμε να το κάνουμε πατώντας επάνω στον αριθμό. Θα
            εμφανιστεί ένα παράθυρο ώστε να μπορέσουμε να επιλέξουμε ένα άλλο
            σημείο.
          </p>
          <p>
            Στην περίπτωση που δεν έγινε η δημιουργία πατώντας το κουμπί,
            βλέπουμε στις πληροφορίες τον Κωδικό Σφάλματος.
          </p>

          <img src={Opencart10} />

          <h5 id="Σημειωσεις">Προσοχή:</h5>
          <ol>
            <li>Τεστάρετε το plugin μόνο με τα tage Api keys.</li>
            <li>
              Επιλέξτε το stage locker: Aegean ΜΕΤΡΟ Ελαιώνας, locker id: 9,
              Address: IEPA OΔOΣ 116, 10447.
            </li>
            <li>
              Για να λάβετε το voucher της παραγγελίας πρέπει η παραγγελία να
              είναι ολοκληρωμένη/πληρωμένη.
            </li>
          </ol>

          <h5>Υποστήριξη</h5>
          <p>
            Για οποιαδήποτε ερώτηση για την διασύνδεση σας με την υπηρεσία μας
            παρακαλώ να καλέστε μας στο 2111005330 ή στείλτε μας στο{" "}
            <a href="mailto: ict@boxnow.gr">ict@boxnow.gr</a>
          </p>
        </div>
      </div>
    </Styles>
  );
};
