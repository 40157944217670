import React, { Fragment, useEffect } from "react";
//import logo from './logo.svg';
import "./App.css";
import { ThemeProvider } from "@mui/system";
import { theme } from "./theme/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, LinearProgress } from "@mui/material";
import { Eshops } from "./pages/Eshops";
import "bootstrap/dist/css/bootstrap.min.css";

import MainPage from "pages/MainPage";
import { Guide } from "pages/Guide";
import { FormCompleted } from "pages/FormCompleted";
import ExternalForm from "pages/ExternalForm";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
//import { ChooseConnection } from 'pages/ChooseConnection';

const AuthLoading = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect();
    }
  }, [inProgress, isAuthenticated, instance]);

  return <LinearProgress />;
};

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Fragment>
          <CssBaseline />
          <AuthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<ExternalForm />} />
                <Route path="/en/" element={<ExternalForm />} />
                <Route path="/eshops" element={<Eshops />} />
                <Route path="/en/eshops" element={<Eshops />} />
                <Route path="/form-completed" element={<FormCompleted />} />
                <Route path="/en/form-completed" element={<FormCompleted />} />
                <Route path="/guide" element={<Guide />} />
                <Route path="/en/guide" element={<FormCompleted />} />
                {/* <Route path='/eshops/magento1' element={<MainPage />}></Route> */}
                <Route path="/eshops/opencart" element={<MainPage />} />
                <Route path="/eshops/cscart" element={<MainPage />} />
                <Route path="/eshops/prestashop" element={<MainPage />} />
                <Route path="/eshops/woocommerce" element={<MainPage />} />
                <Route path="/eshops/shopify" element={<MainPage />} />
                <Route path="/eshops/magento2" element={<MainPage />} />
                <Route path="/tailor-made" element={<MainPage />} />
                <Route path="/partner-portal" element={<MainPage />} />
                <Route path="/eshops/magento1" element={<MainPage />} />
                {/* <Route path='/choose-connection' element={<MainPage />}></Route> */}
                {/* <Route path='*' element={<Navigate to='/form' />} /> */}
              </Routes>
            </BrowserRouter>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <AuthLoading />
          </UnauthenticatedTemplate>
        </Fragment>
      </ThemeProvider>
    </div>
  );
}

export default App;
