import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "./store";
import { useMsal } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { SilentRequest } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Localized version of React Router's `useNavigate`.
 */
export const useLanguageNavigate = () => {
  const navigate = useNavigate();

  return (path: string) => {
    if (window.location.pathname.includes("/en")) {
      navigate(`/en${path}`);
    } else {
      navigate(path);
    }
  };
};

/**
 * Acquire Microsoft access token using MSAL.
 * @returns Microsoft access token.
 */
export const useAccessToken = () => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["api://8d0420a2-0a25-4fe6-9eb7-dbd8188ba249/Invoke"],
      account: instance.getActiveAccount(),
    };

    instance
      .acquireTokenSilent(accessTokenRequest as SilentRequest)
      .then((response) => {
        setAccessToken(response.accessToken);
      })
      .catch((error) => {
        console.error(error);
        instance.loginRedirect();
      });
  }, [instance]);

  return accessToken;
};

/**
 * Returns a warning text, a function to update it
 * and a ref that is automatically scrolled into
 * when the warning is updated.
 * @returns warning, setWarning, warningRef
 */
export const useWarning = () => {
  const [warning, setWarning] = useState("");
  const warningRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (warning && warningRef.current) {
      warningRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [warning]);

  return [warning, setWarning, warningRef] as const;
};
