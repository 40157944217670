import { IconButton, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import useTranslation from "translations/useTranslation";
import { useMsal } from "@azure/msal-react";

const LogoutButton = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const logout = () => {
    instance.logoutRedirect();
  };

  return (
    <IconButton sx={{ borderRadius: 0 }} color="primary" onClick={logout}>
      <Typography
        color="primary"
        fontFamily="sans-serif"
        fontSize="large"
        sx={{ mr: 2 }}
      >
        {t("onboard.logout")}
      </Typography>
      <LogoutIcon fontSize="large" color="primary" />
    </IconButton>
  );
};
export default LogoutButton;
